











































import useSelectItems from "@/use/selectItems";
import { defineComponent, reactive, watch } from "@vue/composition-api";

export default defineComponent({
  components: {
    MEmailEditor: () =>
      import("@/components/molecules/emailEditor/m-email-editor.vue"),
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        type: "",
        title: "Rejestracja na {{ event.name }}",
        design: {},
        editor: {},
      }),
    },
  },

  setup(props, { root }) {
    const { registrationTypeItems } = useSelectItems({ root });

    const model = reactive({
      editor: null,
    });

    const updatedEditor = () => {
      props.value.editor = model.editor;
    };

    watch(
      () => model.editor,
      () => updatedEditor()
    );

    const rules = {
      name: [(v: string) => !!v || root.$tc("rules.enterName")],
      title: [(v: string) => !!v || root.$tc("rules.enterMessageTitle")],
    };

    return { model, registrationTypeItems, rules };
  },
});
